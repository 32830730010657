<template>
  <div class="footer">
    <div class="texta">
      <span class="cursor" @click="to('/friendship')">友情链接</span>
      <span style="padding: 0 20px">|</span>
      <span class="cursor" @click="to('/contact')">联系我们</span>
    </div>
    <div class="textb">Copyright ©2021 国家技术标准创新基地（民航）, All Rights Reserved</div>
    <div class="textb">京ICP备05040221号</div>
    <div class="code">
      <span id="_ideConac"><a href="https://bszs.conac.cn/sitename?method=show&amp;id=25F477B8A9A459C7E053012819ACE7EF" target="_blank"><img id="imgConac" vspace="0" hspace="0" border="0" src="https://dcs.conac.cn/image/blue.png" data-bd-imgshare-binded="1"></a></span>
    </div>
  </div>
</template>
<script>
// import { LoginAdminLogout } from '@/api/http'
export default {
  name: 'Footer',
  components: {},
  data () {
    return {
      msg: '尾部组件',
      code: ''
    }
  },
  watch: {},
  props: {},
  created () {
    this.initialData()
  },
  methods: {
    to (path) {
      const { href } = this.$router.resolve({
        path: path
      })
      window.open(href, '_blank')
    },
    initialData () {}
  }
}
</script>
<style lang="less" scoped>
.footer{
  position: relative;
  height: 150px;
  padding-top: 40px;
  padding-right: 100px;
  text-align: center;
  background-color: #0B1C51;
}
.texta{
  font-size: 16px;
  padding-bottom: 16px;
  color: #E9E9E9;
}
.textb{
  font-size: 12px;
  padding-bottom: 10px;
  color: #999999;
}
.code{
  position: absolute;
  height: 70px;
  width: 70px;
  top: 0;
  right: 25%;
  bottom: 0;
  margin: auto;
}
</style>
