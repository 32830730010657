<template>
  <transition name="demo">
        <div class="toast" v-show="theToast" :class="{'success':type == 'success', 'error':type == 'error'}">
            {{msg}}
        </div>
    </transition>
</template>
<script>
export default {
  name: 'Msg',
  data () {
    return {
      theToast: false,
      type: '',
      msg: ''
    }
  }

}
</script>
<style scoped lang="less">
.toast {
  position: fixed;
  top: 100px;
  width: 350px;
  left: 50%;
  margin-left: -175px;
  padding: 25px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  z-index: 9999;
  opacity: 1;
}
.success{
  border: 1px solid #839af7;
  background-color: #e1e7ff;
  color: #333333;
}
.error{
  border: 1px solid #fa7c7c;
  background-color: #f8cfcf;
  color: #333333;
}
.demo-enter-active,
.demo-leave-active {
  transition: all 0.3s ease-out;
}
.demo-enter {
  opacity: 0;
  transform: translateY(-100px);
}
.demo-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}
</style>
