import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import promise from 'es6-promise'
import 'babel-polyfill'
import Directive from './directive'
import Msg from './hintFrame/msg'
import ConfirmBox from './hintFrame/confirmBox'
import './assets/style/public.css'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb'
import Buttom from '@/components/buttom/Buttom'

promise.polyfill()
Vue.use(Directive)
Vue.use(Msg)
Vue.use(ConfirmBox)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('Buttom', Buttom)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
