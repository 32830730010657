<template>
  <div class="load-button" :class="{'load-button-load': loading}">
    <slot></slot>
    <div v-if="loading" class="load-span">
      <div class="load-span-spinner">
        <div class="load-span-spinner-container load-span-container1">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
        </div>
        <div class="load-span-spinner-container load-span-container2">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { LoginAdminLogout } from '@/api/http'

export default {
  name: 'Buttom',
  components: {},
  data () {
    return {
      msg: '按钮'
    }
  },
  watch: {},
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    initialData () {}
  }
}
</script>
<style lang="less" scoped>
.load-button{
  position: relative;
  cursor: pointer;
}
.load-button-load{
  pointer-events: none;
}
.load-span{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:hsla(0,0%,100%,.5);
}
.load-span-spinner {
  width: 14px;
  height:14px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -7px auto 0;
}
.load-span-container1 > div, .load-span-container2 > div {
  width: 4px;
  height: 4px;
  background-color: #0192FD;
  border-radius: 100%;
  position: absolute;
  -webkit-animation: bouncedelay 1.2s infinite ease-in-out;
  animation: bouncedelay 1.2s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.load-span-spinner .load-span-spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
}
.load-span-container2 {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.circle1 { top: 0; left: 0; }
.circle2 { top: 0; right: 0; }
.circle3 { right: 0; bottom: 0; }
.circle4 { left: 0; bottom: 0; }
.load-span-container2 .circle1 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.load-span-container1 .circle2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.load-span-container2 .circle2 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.load-span-container1 .circle3 {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.load-span-container2 .circle3 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.load-span-container1 .circle4 {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.load-span-container2 .circle4 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
@-webkit-keyframes bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0.0) }
    40% { -webkit-transform: scale(1.0) }
}
@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 40% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}
</style>
