import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/index/home/Home.vue')
      },
      {
        path: 'list',
        name: 'List',
        component: () => import('@/views/index/list/List.vue'),
        children: [
          {
            path: 'normal',
            name: 'ListA',
            component: () => import('@/views/index/list/ListA.vue')
          },
          {
            path: 'material',
            name: 'ListB',
            component: () => import('@/views/index/list/ListB.vue')
          },
          {
            path: 'file',
            name: 'ListC',
            component: () => import('@/views/index/list/ListC.vue')
          },
          {
            path: 'download',
            name: 'ListCC',
            component: () => import('@/views/index/list/ListCC.vue')
          },
          {
            path: 'column',
            name: 'ListE',
            component: () => import('@/views/index/list/ListE.vue')
          },
          {
            path: 'from',
            name: 'ListF',
            component: () => import('@/views/index/list/ListF.vue')
          },
          {
            path: 'unit',
            name: 'ListUnit',
            component: () => import('@/views/index/list/ListUnit.vue')
          },
          {
            path: '*',
            component: () => import('@/views/error/Error.vue')
          }
        ]
      },
      {
        path: '/member',
        name: 'ListMember',
        component: () => import('@/views/index/list/ListMember.vue')
      },
      {
        path: '/search',
        name: 'ListSearch',
        component: () => import('@/views/index/list/ListSearch.vue')
      },
      {
        path: '/detail',
        name: 'Detail',
        component: () => import('@/views/index/detail/Detail.vue')
      },
      {
        path: '/msgdetail',
        name: 'MsgDetail',
        component: () => import('@/views/index/detail/MsgDetail.vue')
      },
      {
        path: '/votedetail',
        name: 'VoteDetail',
        component: () => import('@/views/index/detail/VoteDetail.vue')
      },
      {
        path: '/unitdetail',
        name: 'UnitDetail',
        component: () => import('@/views/index/detail/UnitDetail.vue')
      },
      {
        path: '/materialdetail',
        name: 'MaterialDetail',
        component: () => import('@/views/index/detail/MaterialDetail.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/index/home/Contact.vue')
      },
      {
        path: '/friendship',
        name: 'Friendship',
        component: () => import('@/views/index/home/Friendship.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/login/Login.vue')
  },
  {
    path: '*',
    component: () => import('@/views/error/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
