import ToastComponent from './Msg.vue'

/*
 * this.$msg('蓝底黑字的提示框') // type = 'success'  默认
 * this.$msg('红底黑字的提示框') // type = 'error'
 * this.$msg('黑底白字的提示框') // type不等于以上二个
*/

const Toast = {}
Toast.install = function (Vue) {
  const ToastConstructor = Vue.extend(ToastComponent)
  const instance = new ToastConstructor()
  instance.$mount(document.createElement('div'))
  document.body.appendChild(instance.$el)
  Vue.prototype.$msg = (msg, type = 'success', duration = 2000) => {
    instance.msg = msg
    instance.type = type
    instance.theToast = true
    if (duration !== 'perpetual') {
      setTimeout(() => {
        instance.theToast = false
      }, duration)
    }
  }
}
export default Toast
