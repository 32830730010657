<template>
  <div class="index">
    <!-- <h1>{{msg}}</h1> -->
    <Herder></Herder>
    <div class="article">
      <router-view/>
    </div>
    <Footer></Footer>
    <div v-if="windowsShow" class="uncleaned-box" :style="windowsStyle">
      <div class="uncleaned-text">您有 {{uncleaned}} 条投票未处理</div>
      <div class="btns" style="padding: 20px 0 0 0;">
        <Buttom class="btn" style="background-color: #cdcdcd;color: #333333;margin-right: 15px;" @click.native="uncleanedHeid">
          <span>忽略</span>
        </Buttom>
        <Buttom class="btn" style="background-color: #4E84FF;" @click.native="uncleanedClick">
          <span>前往</span>
        </Buttom>
      </div>
    </div>
    <!-- <div class="pop-up">
      <span>这是一段信息</span>
    </div> -->
    <Pop v-model="isShow" width="480px" title="" @shut="shutFun">
      <div style="padding: 0px 10px 10px 10px;">
        <div style="text-align: center; position: relative;">
          <img style="width: 350px; border-radius: 4px; " src="../../assets/images/1-2.jpg" alt="">
          <div style="width: 120px; margin: 0 auto; margin-top: 10px; position: absolute; left: 165px; bottom: 30px;">
          <div @click="submitForm" style="background-color: orange; cursor: pointer; margin-left: 0;width: 100%; height: 32px; line-height: 32px; font-size: 14px; border-radius: 4px; text-align: center; color: #fff;">
            <span>查看详情</span>
          </div>
        </div>
        </div>
      </div>
    </Pop>
  </div>
</template>

<script>
import Herder from '@/components/herder/Herder.vue'
import Footer from '@/components/footer/Footer.vue'
import Pop from '@/components/pop/Index.vue'
import Buttom from '@/components/buttom/Buttom.vue'
// import { HomeImages } from '@/api/web'
export default {
  components: {
    Herder,
    Footer,
    Pop,
    Buttom
  },
  name: 'Index',
  data () {
    return {
      isShow: false,
      windowsShow: false,
      windowsStyle: 'bottom: -100%;',
      uncleaned: '',
      uncleanedShow: false,
      msg: 'index'
    }
  },
  watch: {
    uncleanedShow: function (n, o) {
      if (n) {
        if (this.uncleaned > 0) {
          this.windowsShowFun()
        }
      }
    }
  },
  created () {
    // if (!window.sessionStorage.getItem('pop-up')) {
    //   this.isShow = true
    //   window.sessionStorage.setItem('pop-up', 1)
    // }
  },
  mounted () {
    // this.$confirm(['删除', '是否要删除这条信息'], (res) => {
    //   // 点击成功事件
    //   // HomeImages({}).then((res) => {
    //   //   if (res) {
    //   //     if (res.code === '000') {
    //   //       // console.log('成功')
    //   //     }
    //   //   }
    //   // })
    //   this.$msg('你已经确认', 'success')
    // }, () => {
    //   this.$msg('你已经关闭', 'error')
    // })
  },
  methods: {
    submitForm () {
      this.shutFun()
      this.$router.push({ path: '/list/normal', query: { id: '396515c1884fe0746f86ea0ceddee204', cid: '4ee57dd1b8fa893b73798064b20ce743' } })
    },
    shutFun () {
      this.isShow = false
    },
    windowsShowFun () {
      const that = this
      if (new Date().getTime() - localStorage.getItem('uncleanedTime') > 60 * 1000 * 60) {
        that.windowsShow = true
        setTimeout(() => {
          that.windowsStyle = 'bottom: 0;'
        }, 1000)
      }
    },
    uncleanedHeid () {
      const that = this
      that.windowsStyle = 'bottom: -100%;'
      setTimeout(() => {
        that.windowsShow = false
        localStorage.setItem('uncleanedTime', new Date().getTime())
      }, 500)
    },
    uncleanedClick () {
      this.uncleanedHeid()
      this.$router.push({ path: this.$store.getters.getHytpUrl })
    },
    windowsHideFun () {
      console.log(88)
      const that = this
      that.windowsStyle = 'bottom: -100%;'
      setTimeout(() => {
        that.windowsShow = false
      }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
.article{
  width: 1230px;
  margin: auto;
  padding: 0 15px;
}
.uncleaned-box{
  // height: 150px;
  width: 250px;
  border-radius: 8px;
  background-color: #ffffff;
  position: fixed;
  bottom: -100%;
  right: 0px;
  border: 1px solid #cdcdcd;
  z-index: 100;
  padding: 25px 0;
  text-align: center;
  transition: all .5s;
}
.uncleaned-text{
  font-size: 16px;
}

.pop-up {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background: red;
}
</style>
