import { post, downloadFile } from './request'

// 登陆
export const LoginAdminLogin = (params) => {
  return post('/system/login', params)
}
// 获取用户昵称
export const GetNikeName = (params) => {
  return post('/cms/i/user/extend/getNickName', params)
}

// 下载文件
export const fileDownload = (path) => {
  downloadFile('/system/files/noAccessDownloadFile', { path: path }).then(res => {
    const blob = new Blob([res.data])
    const fileName = decodeURIComponent(res.headers['content-disposition'].split(';')[1].split('filename=')[1])
    if ('download' in document.createElement('a')) { // 非IE下载
      const elink = document.createElement('a')
      // elink.download = fileName
      elink.download = fileName
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    } else { // IE10+下载
      // navigator.msSaveBlob(blob, fileName)
      navigator.msSaveBlob(blob, fileName)
    }
  })
}
