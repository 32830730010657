import axios from 'axios'
import Vue from 'vue'
import router from '../router'

// 请求失败错误信息提示
function checkCode (message) {
  Vue.prototype.$msg(message, 'error')
}

axios.defaults.timeout = 10000
axios.defaults.withCredentials = true
axios.defaults.headers = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json; charset=UTF-8'
  // 'Content-Type': 'application/x-www-form-urlencoded'
}
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

// 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const pending = []
const cancelToken = axios.CancelToken
const removePending = (config) => {
  // const resUrl = `${config.url}&${config.method}`
  // eslint-disable-next-line no-restricted-syntax
  // for (const p in pending) {
  //   if (pending[p].url === resUrl) { // 当当前请求在数组中存在时执行函数体
  //     pending[p].fn() // 执行取消操作
  //     pending.splice(p, 1) // 把这条记录从数组中移除
  //   }
  // }
}

// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    const url = config.url
    const listUrl = ['/cms/cmsType/getAll']
    if (listUrl.includes(url)) {}
    if (window.localStorage.getItem('token')) {
      config.headers.Authorization = window.localStorage.getItem('token')
    }
    removePending(config) // 在一个ajax发送前执行一下取消操作
    // eslint-disable-next-line new-cap
    config.cancelToken = new cancelToken((cf) => {
      // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
      pending.push({ url: `${config.url}&${config.method}`, fn: cf })
    })
    return config
  },
  (error) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    Promise.reject(error)
)

// 添加返回拦截器
axios.interceptors.response.use(
  (response) => {
    // 在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
    removePending(response.config)
    // 判断是不是 返回码000
    // 如果不是000  同意处理
    const { data } = response || {}
    const code = data.code

    // 正常码数组
    const listCode = ['000', '901']
    if (typeof response !== 'undefined') {
      if (listCode.includes(code)) {
        // 000
        return data
      } else {
        // 不是000
        // 文件下载
        const listUrl = ['/system/files/noAccessDownloadFile']
        if (listUrl.includes(response.config.url)) {
          return response
        } else {
          return checkCode(data.msg)
        }
      }
    }
  },
  (error) => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '请求错误'
          break
        case 401:
          // 清空缓存，跳到登录界面
          localStorage.clear()
          sessionStorage.clear()
          if (error.response.data.msg) {
            // 账号或密码错误
            error.message = error.response.data.msg
          } else {
            error.message = '登录过期，请重新登录'
            if (window.location.pathname !== '/') {
              router.push({ path: '/' })
            }
            // router.push({ path: '/login' })
          }
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = '请求失败'
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器内部错误'
          break
        case 501:
          error.message = '服务未实现'
          break
        case 502:
          error.message = '无法连接服务器'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '连接服务器超时'
          break
        case 505:
          error.message = 'HTTP版本不受支持'
          break
        default:
      }
    } else {
      error.message = '连接错误'
    }
    return checkCode(error.message)
  }
)

export async function post (url, params) {
  try {
    const res = await axios({
      method: 'post',
      url,
      data: params
    })
    return typeof res !== 'undefined' && res
  } catch (err) {
    return checkCode(err.message)
  }
}

export async function get (url, params) {
  try {
    const res = await axios.get(url, { params: params })
    return typeof res !== 'undefined' && res
  } catch (err) {
    return checkCode(err.message)
  }
}
// 下载文件
export async function downloadFile (url, params) {
  try {
    const res = await axios({
      method: 'post',
      url,
      data: params,
      responseType: 'blob'
    })
    return typeof res !== 'undefined' && res
  } catch (err) {
    return err
  }
}
