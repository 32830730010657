/**
 *  确认弹窗
 *     this.$confirm(['删除', '是否要删除这条信息'],(res) => {
 *         //点击成功事件
 *     })
 */

import ConfirmBox from './ConfirmBox.vue'

const confirm = {} // 定义插件对象

confirm.install = (Vue) => {
  const temp = Vue.extend(ConfirmBox)
  // eslint-disable-next-line new-cap
  const tempVm = new temp()
  const tpl = tempVm.$mount().$el
  document.body.appendChild(tpl)
  Vue.prototype.$confirm = (strArr, fun, close = () => {}) => {
    tempVm.strArr = strArr
    tempVm.modFun = fun
    tempVm.closeFun = close
    tempVm.show = true
  }
}
// 导出 confirm
export default confirm
