import { post, get } from './request'

// 王嵩本地调试
export const wangSong = (params) => {
  return post('/cms/pages', params)
}

// 获取网站统计访问数量
export const caWebVisits = (params) => {
  return post('/cms/caWebVisits/vistits', params)
}
// 校验token是否有效
export const authorization = (params) => {
  return get('/system/authorization/sns/auth', params)
}
// 退出登录
export const quitLogin = (params) => {
  return post('/system/w/logout', params)
}
// 获取全部栏目
export const getNavAll = (params) => {
  return post('/cms/cmsType/getAll', params)
}
// 获取栏目结构
export const getListTreeById = (params) => {
  return post('/cms/cmsType/getTreeById', params)
}
// 获取栏目详情
export const getInfoById = (params) => {
  return post('/cms/cmsType/getInfoById', params)
}
// 获取文章列表
export const getList = (params) => {
  return post('/cms/cms/pages', params)
}
// 获取标准计量规范列表
export const getStandardList = (params) => {
  return post('/cms/standard/page', params)
}
// 获取培训资料列表
export const getMaterialsList = (params) => {
  return post('/cms/materials/page', params)
}
// 获取培训资料详情
export const getMaterialsDetail = (params) => {
  return post('/cms/materials/get', params)
}
// 获取文章详情
export const getDetail = (params) => {
  return post('/cms/cms/get', params)
}
// 获取轮播图
export const slideshowPage = (params) => {
  return post('/cms/advertising/slideshowPage', params)
}
// 获取广告位
export const advertisingPage = (params) => {
  return post('/cms/advertising/advertisingPage', params)
}
// 搜索本页
export const wpagesList = (params) => {
  return post('/cms/current/wpages', params)
}
// 搜索本页--已登录
export const wpagesIpagesList = (params) => {
  return post('/cms/i/current/ipages', params)
}
// 搜索全站
export const wpagesGlobalList = (params) => {
  return post('/cms/global/wpages', params)
}
// 搜索全站--已登录
export const wpagesGlobalIpagesList = (params) => {
  return post('/cms/i/global/ipages', params)
}
// 提交意见反馈
export const feedbackAdd = (params) => {
  return post('/cms/feedback/add', params)
}
/**
 * 会员相关
 */
// 未读数量
export const noticeCount = (params) => {
  return post('/cms/i/notice/count', params)
}
// 消息通知列表
export const noticeList = (params) => {
  return post('/cms/i/notice/page', params)
}
// 消息通知详情
export const noticeListDetails = (params) => {
  return post('/cms/i/notice/details', params)
}
// 投票列表
export const votePageList = (params) => {
  return post('/cms/i/vote/voteWebPage', params)
}
// 投票详情
export const voteWebStatusDetails = (params) => {
  return post('/cms/i/vote/voteWebStatus', params)
}
// 投票
export const voteWebVoteUser = (params) => {
  return post('/cms/i/vote/voteUser', params)
}
/***
 * 成员单位
 */
// 成员单位列表
export const companyeList = (params) => {
  return post('/cms/company/page', params)
}
// 成员单位详情
export const companyeDetails = (params) => {
  return post('/cms/company/details', params)
}
