<template>
    <div class="input-view" :style="componentStyle">
      <div v-if="inputLabel && labelWidth > 0" class="inp-ladel" :style="inputLabelStyle">
        <span class="ladel-span" :class="{'inp-ladel-required':required}">{{inputLabel}}:</span>
      </div>
      <div v-if="type == 'textarea'" class="inp-cont dong" :style="inputContStyle" :class="{'inp-cont-err':hint, 'inp-focus':inputFocus}">
        <textarea rows="5" :value="inputValue" class="textarea-style" @focus="inputFocus = true;" @blur="inputBlur" :disabled="disabled"
          @input="inputChange($event)" :placeholder="inputPlaceholder"></textarea>
      </div>
      <div v-else :style="`height: ${height}px;`">
        <div class="inp-cont dong" :style="inputContStyle" :class="{'inp-cont-err':hint, 'inp-focus':inputFocus}">
          <div v-if="icon" class="icon-box">
            <div class="icon-text">
              <img :src="icon" alt="">
            </div>
          </div>
          <div class="inp-k" :class="{'inp-k-margin-left':icon, 'inp-k-margin-right':units || type == 'password'}">
            <input v-if="type == 'integer'" :value="inputValue" class="inp" :type="inputType" :placeholder="inputPlaceholder"
                  :disabled="disabled"
                  :readonly="readonly"
                  @focus="inputFocus = true;" @blur="inputBlur"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  @input="inputChange($event)">
            <input v-else :value="inputValue" class="inp" :class="{'inp-disabled':disabled}" :type="inputType" :placeholder="inputPlaceholder"
                  @focus="inputFocus = true;" @blur="inputBlur"
                  :disabled="disabled"
                  :readonly="readonly"
                  :oninput="inputOninput"
                  @input="inputChange($event)">
          </div>
          <div v-if="units" class="input-units">
            <span>{{units}}</span>
          </div>
          <div v-if="type == 'password'" class="input-password">
            <img v-if="inputType != 'password'" :src="zhengyan" alt=""  @click="inputType = 'password'">
            <img v-else :src="biyan" alt="" @click="inputType = 'text'">
          </div>
        </div>
      </div>
      <div v-show="hint" class="err-hint" ref="errHint">{{hintText}}</div>
    </div>
</template>

<script>
/*
  import ComponentTemplate from '@/components/ComponentTemplate'
  <TableInput></TableInput>
  width：         整个组件的宽度（组件是行间块元素，用的是px）只穿数值就可以
  inline:         块级元素还是行内块级元素
  label：         左边文字
  labelWidth：    左边文字宽度（默认90px）
  labelAlign:     左边文字对齐方式
  placeholder:    占位文字（如不传和左边文字一样）
  height:         高(默认40) 只穿数值就可以(大于40)
  required：      是否为必填项（左边加小红星）
  units:          单位（米、吨、个）
  icon:           input框左边图标
  v-model:        绑定的值
  type：          类型
                  number：      只能输入带小数点的数字，返回的是可以带小数点的数字
                  integer：     只能输入整数，返回的是整数
                  password:     密码，目前采用默认的
  rules：         验证条件（支持type1: number,integer,默认）
                    '2,100'      数字区间其他字符长度 直传一个数就是最小值或最少长度
*/

export default {
  name: 'TableInput',
  data () {
    return {
      zhengyan: require('../../assets/images/zhengyan.png'),
      biyan: require('../../assets/images/biyan.png'),
      msg: '组件初始模板',
      pass: false, // true 验证通过  false 验证不通过
      hint: false,
      hintText: '', // 错误提示
      inputFocus: false,
      inputType: '', // input的type值
      inputOninput: '', // number和integer的正则验证  返回的还是字符串
      inputValue: '', // 值
      inputHeight: '', // 高度
      inputLabel: '', // 左边文字
      inputPlaceholder: '', // 占位字符
      inputLabelStyle: '', // 左边宽度-样式
      inputContStyle: '', // input框样式
      componentStyle: '', // 整个组件的宽度

      inputRules: '' // 验证
    }
  },
  components: {},
  props: {
    value: {
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number
    },
    marginBottom: {
      type: Number,
      default: 20
    },
    inline: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: Number,
      default: 90
    },
    labelAlign: {
      type: String,
      default: 'left'
    },
    height: {
      type: Number,
      default: 40
    },
    required: {
      type: Boolean,
      default: false
    },
    units: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    // 下边是验证所用
    prop: {
      type: String,
      default: ''
    },
    rules: {
      type: Array
    }
  },
  watch: {
    '$props.value': function (n, o) {
      this.inputValue = n
      if (n) {
        this.inputVerifyControl(n, this.$props.type)
      } else {
        this.pass = false
      }
    }
  },
  created () {
    this.initialData()
  },
  mounted () {
    if (this.$props.labelWidth !== 90) {
      this.$refs.errHint.style.cssText = `left: ${parseInt(this.$props.labelWidth) + 5}px;`
    } else {
      this.$refs.errHint.style.cssText = 'left: 95px;'
    }
  },
  methods: {
    // 初始化数据
    initialData () {
      if (this.$props.type === 'password') {
        this.inputType = 'password'
      } else {
        this.inputType = 'text'
        if (this.$props.type === 'number') {
          this.inputOninput = "value=value.replace(/[^0-9.]/g,'')"
        }
      }

      if (this.$props.rules) {
        this.inputRules = this.$props.rules
      }
      if (this.$props.value) {
        this.inputVerifyControl(this.$props.value, this.$props.type)
      }
      this.inputLabel = this.$props.label
      if (this.$props.placeholder) {
        this.inputPlaceholder = this.$props.placeholder
      } else {
        this.inputPlaceholder = this.$props.label
      }
      if (this.$props.width) {
        this.componentStyle = `width: ${this.$props.width}px;`
      }
      if (this.$props.inline) {
        this.componentStyle = this.componentStyle + 'display: inline-block;'
      }
      this.componentStyle = `${this.componentStyle}margin-bottom: ${this.$props.marginBottom}px;`
      const contStyle = `margin-left: ${this.$props.labelWidth}px;`
      const labelStyle = `width: ${this.$props.labelWidth}px;line-height: ${this.$props.height}px;text-align: ${this.$props.labelAlign};`
      this.inputLabelStyle = labelStyle
      this.inputContStyle = contStyle
    },
    // input失去焦点
    inputBlur () {
      this.inputFocus = false
      this.inputVerifyControl(this.inputValue, this.$props.type)
    },
    // 实时接收input值 进行处理
    inputChange (e) {
      const val = e.target.value
      this.inputVerifyControl(val, this.$props.type)
    },
    // input得空和数字验证
    inputVerifyControl (val, type) {
      let hint = false
      let hintText = ''
      if (!val) {
        if (this.$props.required) {
          hint = true
          hintText = `${this.$props.label}不能为空`
        }
        return this.setResult(val, hint, hintText)
      }
      if (type === 'number' || type === 'integer') {
        if (isNaN(val)) {
          hintText = '请输入数字'
          hint = true
          return this.setResult(val, hint, hintText)
        }
      }
      if (this.inputRules) {
        return this.verify(val)
      }
      this.setResult(val, hint, hintText)
    },
    // rules验证
    verify (val) {
      let hint = false
      let hintText = ''
      const verifyArr = this.inputRules
      verifyArr.forEach((item, index) => {
        if (item.type === 'regular') {
          if (!item.verify.test(val)) {
            hint = true
            hintText = item.msg
          }
        }
      })
      this.setResult(val, hint, hintText)
    },
    // 设置input值
    setResult (val, bool, text) {
      let value = val
      if (value) {
        if (this.$props.type === 'number') {
          value = parseFloat(value)
        }
        if (this.$props.type === 'integer') {
          value = parseInt(value)
        }
      }
      this.inputValue = value
      this.$emit('input', value)
      this.hint = bool
      this.pass = !bool
      this.hintText = text
    }
  }
}
</script>

<style lang="less" scoped>
.input-view {
  position: relative;
}
.inp-ladel{
  float: left;
}
.ladel-span{
  font-size: 15px;
  line-height: 40px;
  padding: 0 10px;
  position: relative;
}
.inp-ladel-required::before {
  content: "*";
  color: #ff4136;
  line-height: 18px;
  font-size: 18px;
  position: absolute;
  left: -1px;
  top: 50%;
  margin-top: -6px;
}
.inp-cont{
  margin-left: 90px;
  height: 100%;
  position: relative;
  border-radius: 4px;
  border: 1px solid #CDD5DD;
  background-color: #ffffff;
  overflow: hidden;
}
.inp-cont-err{
    border: 1px solid #ff4136;
}
.inp-cont:hover{
    border: 1px solid #0192FD;
}
.inp-focus{
    border: 1px solid #0192FD;
    box-shadow: 0 1px 4px 2px rgba(128,201,254,0.6);
}
.icon-box{
  width: 40px;
  height: 20px;
  border-right: 1px solid #D8D8D8;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: 0;
}
.icon-text{
  height: 20px;
  width: 20px;
  margin: auto;
  line-height: 20px;
  font-size: 20px;
  text-align: center;
}
.icon-text img{
  display: block;
  height: 100%;
  width: 100%;
}
.inp{
  height: 100%;
  width: 100%;
  padding: 0 8px;
  color: #2c3e50;
  font-size: 15px;
  border: 1px solid #ffffff;
}
.inp-disabled{
  color: #777777;
  cursor: not-allowed;
}
.dong{
  transition: all .2s linear;
}
.inp-k{
  height: 100%;
  position: relative;
}
.inp-k-margin-right{
  margin-right: 45px;
}
.inp-k-margin-left{
  margin-left: 40px;
}
.input-units{
  height: 100%;
  width: 45px;
  background-color: #efeded;
  border-left: 1px solid #CDD5DD;
  position: absolute;
  top: 0;
  right: 0;
}
.input-password{
  height: 100%;
  width: 45px;
  position: absolute;
  top: 0;
  right: 0;
}
.input-password img{
  display: block;
  height: 20px;
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.input-units span{
  display: inline-block;
  height: 20px;
  font-size: 15px;
  line-height: 20px;
  color: #777777;
  text-align: center;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}
.err-hint{
  position: absolute;
  top: 100%;
  color: #ff4136;
  left: 5px;
  font-size: 13px;
}
      .textarea-style{
        resize:none;
        width: 100%;
        padding: 5px 10px;
        color: #222222;
        font-weight: 400;
        background-color: #FFF;
        background-image: none;
        font-size: 15px;
      }
</style>
