<style scoped>
.tch {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.tch-a {
  animation: tiaoooo 0.3s ease-out;
}
@keyframes tiaoooo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.tch-qu-ba {
  animation: tch-hui 0.3s ease-in;
}
@keyframes tch-hui {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.tch-cont {
  width: 400px;
  position: absolute;
  top: 150px;
  left: 50%;
  margin-left: -200px;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 5px 15px 25px 15px;
  position: relative;
  overflow: hidden;
}
.lai-a {
  animation: tiao 0.3s ease-out;
}
@keyframes tiao {
  0% {
    /* transform: scale(1.5); */
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    /* transform: scale(1); */
    transform: translateY(0px);
    opacity: 1;
  }
}
.cont-qu-ba {
  animation: cont-hui 0.3s ease-in;
}
@keyframes cont-hui {
  0% {
    /* transform: scale(1); */
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    /* transform: scale(1.3); */
    transform: translateY(-50px);

    opacity: 0;
  }
}
.tch-top {
  padding-left: 5px;
  height: 36px;
  line-height: 36px;
  font-size: 15px;
  font-weight: bold;
  border-bottom: 1px solid #cdcdcd;
}
.tch-center {
  padding: 25px 5px;
  text-align: center;
}
.tch-bot {
  text-align: center;
}
.tch-but {
  display: inline-block;
  height: 32px;
  width: 80px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 10px;
}
.tch-but:hover {
  opacity: 0.7;
}
.tch-but.off {
  line-height: 30px;
  color: #0067ff;
  border: 1px solid #0067ff;
}
.tch-but.on {
  line-height: 32px;
  background-color: #0067ff;
  color: #ffffff;
}
</style>

<template>
  <div v-if="show">
    <div class="tch" :class="[show ? 'tch-a' : '', qu_ba ? 'tch-qu-ba' : '']">
        <div class="tch-cont"
          :class="[show ? 'lai-a' : '', qu_ba ? 'cont-qu-ba' : '']"
        >
          <div class="tch-top">
            <span>确认{{strArr[0]}}</span>
          </div>
          <div class="tch-center">
            <div class="text-text">
              <span>{{strArr[1]}}</span>
            </div>
          </div>
          <div class="tch-bot">
            <div class="tch-but on" @click="on_off(true)">{{strArr[0]}}</div>
            <div class="tch-but off" @click="on_off(false)">取消</div>
          </div>
          <!-- <div class="tch-shut" @click="on_off(false)"></div> -->
        </div>
    </div>
  </div>
</template>

<script>
/*
  this.$tch('delete', () => { console.log(123) })
*/
export default {
  name: 'DialogBox',
  data () {
    return {
      strArr: '',
      show: false,
      qu_ba: false
    }
  },
  components: {},
  watch: {
    strArr: function (n, o) {
      if (n) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.removeProperty('overflow')
        document.getElementsByTagName('body')[0].style.removeProperty('overflow')
      }
    }
  },
  computed: {},
  methods: {
    on_off (e) {
      const that = this
      that.qu_ba = true
      setTimeout(() => {
        that.strArr = ''
        that.qu_ba = false
        that.show = false
        // that.$parent.ConfirmDialogShow = false
        if (e) {
          // that.$emit('ConfirmDialogFun', e)
          that.modFun()
        } else {
          that.closeFun()
        }
      }, 280)
    },
    modFun (e) {},
    closeFun (e) {}
  },
  created () {},
  mounted () {},
  beforeDestroy () {},
  destroyed () {}
}
</script>
