<template>
  <div class="breadcrumb-box">
    <span v-for="(item, index) of list" :key="index" class="breadcrumb-item">
      <router-link v-if="item.path" :to="item.path" class="breadcrumb-link">{{item.name}}</router-link>
      <span v-else class="breadcrumb-link-no">{{item.name}}</span>
      <span v-if="index < list.length - 1" style="margin: 0 5px;">-></span>
    </span>
  </div>
</template>
<script>

export default {
  name: 'Breadcrumb',
  data () {
    return {}
  },
  props: {
    separator: {
      type: String,
      default: '/'
    },
    list: {
      type: Array
    }
  }
}
</script>
<style lang="less" scoped>
.breadcrumb-box{
  padding: 20px 0;
}
.breadcrumb-item{
  color: #999999;
}
.breadcrumb-link{
  color: #999999;
}
.breadcrumb-link:hover{
  color: #155BDD;
}
</style>
