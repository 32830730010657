import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    hytpUrl: ''
  },
  getters: {
    getHytpUrl (state) {
      return state.hytpUrl
    }
  },
  mutations: {
    setHytpUrl (state, url) {
      state.hytpUrl = url
    }
  }
})
