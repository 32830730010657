<template>
  <div class="table-form">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'TableFrom',
  data () {
    return {
      loading: false
    }
  },
  components: {},
  props: {
    formData: {
      type: Object
    },
    rules: {
      type: Object
    }
  },
  watch: {},
  methods: {
    fromVerify () {
      const that = this
      return new Promise((resolve, reject) => {
        let pass = true
        const errList = []
        for (const item of that.$children) {
          if (item.$vnode.componentOptions.tag.indexOf('Input') >= 0) {
            if (!item.pass && item._props.required) {
              item.inputVerifyControl(item._props.value, item._props.type)
              pass = false
              const obj = {
                err: item.inputLabel
              }
              errList.push(obj)
            }
          }
        }
        const obj = {}
        if (pass) {
          obj.code = '000'
          obj.data = 'ok'
          resolve(obj)
        } else {
          obj.code = '111'
          obj.data = errList
          resolve(obj)
        }
      })
    }
  },
  mounted () {
    for (const rItem in this.$props.rules) {
      for (const cItem of this.$children) {
        if (cItem._props.prop === rItem) {
          if (!cItem._props.rules) {
            cItem.inputRules = this.$props.rules[rItem]
            if (cItem._props.value) {
              cItem.inputVerifyControl(cItem._props.value, cItem._props.type)
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.table-form{
  padding: 10px 0;
  position: relative;
}
</style>
